
  import { computed, defineComponent, onMounted, onUnmounted, Ref, ref } from 'vue'

  const __default__ = defineComponent({
    setup() {
      const el = ref(null) as unknown as Ref<HTMLElement>
      const isScrolledToTop = ref(true)
      const isScrolledToBottom = ref(true)

      function updateScrollPosition() {
        isScrolledToBottom.value = el.value.scrollHeight - el.value.scrollTop - el.value.clientHeight < 1
        isScrolledToTop.value = el.value.scrollTop < 1
      }

      const maskTop = computed(() => isScrolledToTop.value ? '0' : '2em')
      const maskBottom = computed(() => isScrolledToBottom.value ? '0' : '2em')

      const resizeObserver = new ResizeObserver(() => {
        updateScrollPosition()
      })

      onMounted(() => {
        resizeObserver.observe(el.value)
      })
      onUnmounted(() => {
        resizeObserver.disconnect()
      })

      return {
        el,
        updateScrollPosition,
        maskTop,
        maskBottom,
      }
    },
  })

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "0d665b20": (_vm.maskTop),
  "34292f30": (_vm.maskBottom)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__