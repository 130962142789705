import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=22f79810&scoped=true&functional=true"
var script = {}
import style0 from "./Avatar.vue?vue&type=style&index=0&id=22f79810&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "22f79810",
  null
  
)

export default component.exports